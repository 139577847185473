<!--  -->
<template>
  <div>
    <div class="erg-uhwf">
      <div class="eg_ygw">
        <div class="e_hug">
          <div>
            <span class="eg_hywf">车世际的</span>
            <span class="eg_bf">8大</span>
            <span class="eg_hywf">主营业务</span>
          </div>
          <div class="jihwf">
            养车 租车 新车 二手车 车辆托管 司机招募 停驶无忧 停车赚绿码
          </div>
          <div class="erg_hygf">
            <div class="eg_gfdbaf">一、车辆平台运营（车主闲置车辆）；</div>
            <div class="eg_gfdbaf">
              二、网约车平台招募，推荐介绍司机的引入B端C端的互换；
            </div>
            <div class="eg_gfdbaf">
              三、让消费者更方便出行，租车修车通过网络快速查询，同时不仅价格透明，还能随时参与商家的优惠活动；
            </div>
            <div class="eg_gfdbaf">
              四、新车、二手车推荐低价并且得到相应的报酬，有修理厂二手车市场的介入，让用车更加放心，服务更加贴心，售后更加用心，价格更加亲民；
            </div>
            <div class="eg_gfdbaf">
              五、相应政策号召停车赚绿码，可以抵扣平台相对应商品的优惠券及提现功能；
            </div>
            <div class="eg_gfdbaf">
              六、自身车辆买卖得到实质性的优惠，多商家对比，平台免费评估；
            </div>
            <div class="eg_gfdbaf">
              七、消费者自身资源变现，人脉圈层多元化，得到进一步深化，资源共享；
            </div>
            <div class="eg_gfdbaf">
              八、通过停驶无忧为自己省钱，提供安全保障。
            </div>
          </div>
        </div>
        <div class="erg_huywef">
          <img
            class="img-responsive"
            src="../../../image/pingtaiyewu.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="huheg_bbwef">
        <img src="../../../image/pingtaiyewubanner.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.erg-uhwf {
  width: 100%;
}
.eg_ygw {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
}
.eg_hywf {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  line-height: 48px;
  color: #272727;
}
.eg_bf {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  line-height: 48px;
  color: #ff6600;
}
.jihwf {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 48px;
  color: #272727;
}

.erg_hygf {
  padding-top: 130px;
}
.eg_gfdbaf {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 32px;
  color: #272727;
}

.e_hug {
  width: 578px;
  margin-right: 224px;
}

.erg_huywef {
  width: 638px;
}

.img-responsive {
  width: 100%;
  height: auto;
}
.huheg_bbwef{
    width: 100%;
    padding-top: 120px;
}
</style>